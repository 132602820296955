body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "GothamBold";
  src: url("./fonts/Gotham-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Gotham-Bold.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/Gotham-Bold.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/Gotham-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Gotham-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Gotham-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamBook";
  src: url("./fonts/Gotham-Book.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Gotham-Book.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/Gotham-Book.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/Gotham-Book.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Gotham-Book.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Gotham-Book.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "GothamBook" !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GothamBold" !important;
}

#bg-transparent {
  background-color: transparent;
  transition: background-color 0.3s linear;
}
@media only screen and (max-width: 600px) {
  #bg-transparent {
    background-color: #c02026;
  }
  .hideAtMobile {
    display: none;
  }
  .toRi {
    margin-left: 20px;
  }
}
#bg-red {
  background-color: #c02026;
  transition: background-color 0.3s linear;
}

/* Carousel */
.carousel-main-div {
  background-image: url("./images/carousel/carousel-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  .carousel-item-c {
    margin-top: 15vh;
  }
  .carousel-caption-c {
    position: absolute;
    top: 10vh;
    left: 3% !important;
    text-align: inherit !important;
    margin-right: -220px;
  }
  .carousel-p {
    width: 50%;
    margin-left: 5vw;
    padding-right: 10vw;
    margin-top: 5vh;
  }

  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
  /* Carousel indicators */
  .carousel-indicators {
    /* position: absolute !important; */
    bottom: -10vh !important;
    left: 70% !important;
    margin-right: 0% !important;
  }
  .carousel-indicators li {
    box-sizing: content-box !important;
    -ms-flex: 0 1 auto !important;
    flex: 0 1 auto !important;
    /* width: 30px;
    height: 3px; */
    margin-right: 3px !important;
    margin-left: 3px !important;
    text-indent: -999px !important;
    cursor: pointer !important;
    /* background-color: #fff; */
    background-clip: padding-box !important;
    /* border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; */
    opacity: 0.5 !important;
    transition: opacity 0.6s ease !important;
    /* custom */
    margin-right: 12px !important;
    margin-bottom: 1px !important;
    width: 17px !important;
    height: 17px !important;
    background-color: transparent !important;
    border: 1px solid #fff !important;
  }
  .carousel-indicators li:hover {
    background-color: #fff !important;
  }
  .carousel-indicators .active {
    background-color: #fff !important;
    border: 1px solid transparent !important;
  }
}
.carousel-main-div {
  .carouselcontact {
    margin-top: 30px;
    font-size: 14px;
    letter-spacing: 0.58px;
    // line-height: 45.2px;
    a:hover {
      text-decoration: none;
      .mailH {
        text-decoration: underline;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .carousel-main-div {
    .carousel-item-c {
      background-color: #000000;
      img {
        opacity: 0.5;
      }
    }
    .carousel-indicators {
      /* position: absolute !important; */
      bottom: -10vh !important;
      left: 50% !important;
    }
    margin-top: 10vh;
    h1 {
      font-size: 22.5px;
    }
    p {
      font-size: 15px;
    }
    .carouselcontact {
      width: 80%;
      margin-top: 100px;
      font-size: 10px;
      .contactMail {
        width: 90%;
        margin-left: 20px;
        .mailH {
          margin-left: 67px;
          text-decoration: underline;
        }
      }
    }
  }
}
// ???
.navbar-div {
  .nav-link:hover {
    font-weight: bold;
    // padding-top: 5px;
    text-decoration: underline;
  }
  .dropdown-menu {
    background-color: rgba(192, 32, 37, 0.9);
    border-radius: 0px;
    border: none;
  }
  .dropdown-toggle {
    color: #fff !important;
  }
}
// Section 4

#section4-card {
  position: absolute;
  top: 20vh;
  right: 15vw;
  background-color: #c02026;
  opacity: 0.9;
  border-radius: 0px;
}
#section4-card-body {
  height: 16rem;
}
@media only screen and (max-width: 600px) {
  #section4-card {
    top: 40vh;
    right: 22vw;
    width: 12rem;
    height: 10rem;
    .section4-li-check-list {
      font-size: 11px;
      margin-top: 0px !important;
      padding-left: 0px !important;
    }
  }
}
.section4-li-check-list {
  list-style: none;
}
.home-section4-pattern {
  position: absolute;
  z-index: -1;
}
@media only screen and (max-width: 600px) {
  .home-section4-div {
    height: 120vh;
    .row {
      display: block;
    }
  }
  .section4-safety {
    h1 {
      font-size: 30px;
      margin-top: 0px !important;
      text-align: center;
    }
    p {
      font-size: 14px;
      margin-right: 0px !important;
      margin-left: 0px !important;
      text-align: center;
    }
    button {
      font-size: 11px;
      padding: 5px !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      margin: 0px !important;
    }
    .btnl {
      text-align: center;
    }
  }
}
/* Section 2 */
.home-section2-red-div {
  margin-right: 10vw !important;
  position: absolute;
  top: 15vh;
  left: -10vw;
  width: 40vw;
  background-color: rgba(192, 32, 38, 0.879);
  padding: 80px;
}
@media only screen and (max-width: 600px) {
  .home-section-2-main {
    height: 535px;
    .btna {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .homesection2img1 {
    margin-top: 45px;
    padding-top: 45px;
  }
  .home-section2-red-div {
    width: 45vw;

    position: absolute;
    top: 1vh;
    left: 0vw;
    padding: 15px;
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 10px;
      margin-left: 0px !important;
      margin-top: 0px !important;
    }
    button {
      font-size: 11px;
      padding: 5px !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      margin: 0px !important;
    }
  }
}
/* Section 3 */
.home-section3-main-div {
  background-image: url("./images/home/home-section3-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 95vh;
}
@media only screen and (max-width: 600px) {
  .home-section3-main-div {
    height: 220vh;
    .figure {
      width: 200px;
    }
    button {
      padding: 15px 30px !important;
    }
  }
}
/* .rgba-red-strong,
.rgba-red-strong:after {
  background-color: rgba(36, 7, 83, 0.7) !important;
} */
#home-section3-img-h6 {
  position: absolute;
  top: 25%;
  left: 37%;
  z-index: 999;
}

/* Subscribe section */
.modal-content {
  border: none !important;
  border-radius: 0px !important;
  button {
    background-color: #eeeeee !important;
  }
}
.sectionsubscribe-main-div {
  background-color: #f5f5f5;
  padding: 40px;

  @media only screen and (max-width: 600px) {
    .subCOl1 {
      h6 {
        text-align: center;
      }
      h3 {
        text-align: center;
        margin-bottom: 45px;
      }
    }
  }
}

.input-frame-subscribe {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  padding-right: 100px !important;
  padding-left: 30px !important;
  border-radius: 0px !important;
  border: none !important;
}

.subBtnDiv {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .subBtnDiv {
    margin-top: 20px;
  }
}

#mailchimpframe {
  // display: none;
}
#mailchimpframe-div {
  height: 60vh;
}
#button-frame-subscribe {
  border-radius: 0px;
  border: 1.4px solid #000;
  color: #000;
  font-weight: bold;
}
#button-frame-subscribe:hover {
  color: #fff;
}
/* Footer */
.footer {
  background-color: #c02026;
  .nav-link:hover {
    font-weight: bold;
  }
}
.footer2 {
  background-color: #af1d23;
}
.footer-logo {
  cursor: pointer;
}
// headers
.each-page-header {
  background-image: url("./images/app2/app2-header-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  @media only screen and (max-width: 600px) {
    height: 35vh;
  }
  h1 {
    padding-top: 14vh;
  }
  @media only screen and (max-width: 600px) {
    h1 {
      padding-top: 18vh;
    }
  }
}

.app2-section2-absolute1 {
  position: absolute;
  top: -30px;
  z-index: -1;
}
.app2-section1-id1 {
  margin-top: 15vh;
}
@media only screen and (max-width: 600px) {
  .app2-section1-main {
    margin-left: 15px;
    margin-right: 15px;
    h1 {
      font-size: 1.7rem;
    }
    p {
      font-size: 0.8rem;
    }
    h6 {
      font-size: 0.9rem;
    }
    ul li {
      font-size: 0.7rem;
    }
  }
}
.moved-section {
  padding-top: 50px;
  padding-bottom: 200px;
  .row-specific-246 {
    width: 28rem;
    height: 37rem;
    border-radius: 0px;
    background-color: #c02026;
  }
}
@media only screen and (max-width: 600px) {
  .moved-section {
    padding-top: 0px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 600px) {
  .eachM {
    display: none;
  }
  .safety {
    display: none;
  }
}
.app2-section2-absolute2 {
  position: absolute;
  top: 2vh;
  left: 4vw;
  z-index: -1;
}
.app2-section3-main-div {
  background-image: url("./images/app2/app2-section2-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin-top: 10vh;
}
.app2-section3-pattern {
  position: absolute;
}
.app2-section4-main-row {
  height: 90vh;
}
/* .app2-section4-image-div {
  background-image: url("./images/app2/app2-section4-background.jpg");
} */
#app2-section4-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.app2-section4-red-div {
  background-color: #c02026;
}
@media only screen and (max-width: 600px) {
  .app2-section4-red-div {
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
    .pad {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
}

.app3-section-header {
  background-image: url("./images/app3/app3-header-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.app3-section-header-h1 {
  padding-top: 14vh;
}
.app3-section1-absolute {
  position: absolute;
  top: -3vh;
  left: -3vw;
}
.koreaPilot {
  z-index: 999 !important;
}

.pilotPattern {
  position: absolute;
  bottom: 75px;
  z-index: -99 !important;
}

@media only screen and (max-width: 600px) {
  .pilotPattern {
    bottom: 125px;
    left: 25%;
  }
  .descRows {
    display: inline-block !important;
    margin-top: 50px;
    h3 {
      text-align: center;
    }
    h6 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    // !imp
    ul,
    p {
      padding-left: 0px !important;
    }

    .socialRows-Mob {
      display: inline-block !important;
      width: 100%;
      text-align: center;
      padding-top: 50px;
    }
  }

  .app3-section1-absolute {
    width: 40%;
  }
  .socialRows {
    display: none !important;
  }
  #SocialRow {
    margin-top: 10px !important;
    margin-bottom: 35px !important;
  }
}

/* App 4 */
.app4-section-header {
  background-image: url("./images/app3/app3-header-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.app4-section-header-h1 {
  padding-top: 14vh;
}
.app4-faq-button {
  background-color: transparent !important;
  border: none !important;
}

/* App 5 */
.app5-section-header {
  background-image: url("./images/app3/app3-header-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.app5-section-header-h1 {
  padding-top: 14vh;
}

.app5-section1 {
  margin-top: 150px;
  margin-bottom: 150px;
  a {
    text-decoration: none !important;
  }
  button {
    border-radius: 0px;
  }
  button:hover {
    color: #fff !important;
  }
}
@media only screen and (max-width: 600px) {
  .app5-section1 {
    .row {
      display: block;
    }
    .col {
      margin-left: 0px !important;
    }
    h1 {
      text-align: center;
    }
    .phiw {
      margin-top: 20px;
    }
  }
}
/* App 6 */
.app6-section-header {
  background-image: url("./images/app3/app3-header-background.png");
  background-color: #c02026;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.app6-section-header-h1 {
  padding-top: 14vh;
}
/* Inputs and buttons */
#button-frame-1 {
  border-radius: 0px;
}

/* Social row */
.social-row-button-red-shadow:hover {
  box-shadow: 5px 5px #c02026;
}
.social-row-button-white-shadow:hover {
  box-shadow: 5px 5px #fff;
}
/* Controlled Tabs  */
.FAQ-collapse {
  font-size: 0.9rem;
  .nav-tabs {
    /* border-radius: 0px !important; */
  }
  .nav-tabs a {
    padding: 25px 50px;
  }
  .app4-faq-button {
    outline: none !important;
    box-shadow: 0px 2px 15px rgba(255, 255, 255, 0) !important;
  }
  .app4-faq-collapse {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1) !important;
  }
}
@media only screen and (max-width: 600px) {
  .FAQ-collapse {
    font-size: 0.7rem;
  }
}

/* Contact form */
.contact-form-submit-button {
  float: right;
  border-radius: 0px !important;
  border: 1px solid #000 !important;
}
.addressCol {
  a {
    color: #000 !important;
  }
}
@media only screen and (max-width: 600px) {
  .addressCol {
    h6 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .socialRow {
    text-align: center;
  }
  .feedbackform {
    margin-top: 75px;
    .contact-form-submit-button-div {
      text-align: center;
      .contact-form-submit-button {
        float: none;
      }
    }
  }
}

// ::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::SCSS::
.products {
  a {
    color: #000;
    font-size: 15px;
    border-radius: 0px !important;
    padding: 25px;
    font-weight: bold;
  }
  a:hover {
    color: #000;
    font-weight: bold;
  }
  .nav-tabs {
    border-bottom: 1px solid #000 !important;
  }
  .nav-link.active {
    border-color: #000 #000 #fff !important;
  }
}
// only for cargo hide
.x2 {
  .carousel-indicators li {
    display: none;
  }
}
.products-carousel {
  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
  .carousel-indicators li {
    background-color: transparent;
    width: 17px;
    height: 17px;
    border: 1px solid #fff;
  }
  .active {
    background-color: #fff !important;
    border: 1px solid transparent !important;
  }
}
// Privacy and terms
.privacyAndTerms {
  margin-top: 150px;
  margin-bottom: 150px;
  @media only screen and (max-width: 600px) {
    .row {
      display: inline-block !important;
    }
    h1,
    p {
      text-align: center;
    }
  }
}
// Pricing req
.pricingReq {
  margin-top: 100px;
  margin-bottom: 100px;
  @media only screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .displayNoneAll {
    display: none !important;
  }
}
.specs-warranty-div {
  margin-top: 150px;
  margin-bottom: 150px;
  ul {
    li {
      list-style: none;
    }
  }
  .nkl8 {
    li {
      width: 35%;
    }
    b {
      float: right;
    }
  }
}

@media only screen and (max-width: 600px) {
  #youtubevideo {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .prodcont {
    padding-right: 0px !important;
    padding-left: 0px !important;
    .tabinside {
      margin-left: 20px;
    }
    .nav-tabs {
      a {
        font-size: 9px !important;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
}
